import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import {useFetchAccountSearch} from "../../queries/useFetchAccountSearch";
import {useHistory} from "react-router";
import styled from "@emotion/styled";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box"; // Adjust the import path to your config file




const AccountSearch = ({drawerWidth}) => {
  const [inputValue, setInputValue] = useState('');
  const [recentAccounts, setRecentAccounts] = useState([]);
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);

  // Custom Popper to align the dropdown to the right
const CustomPopper = styled(Popper)(({ theme }) => ({
  [`& .MuiAutocomplete-paper`]: {
    marginLeft: drawerWidth, // Adjust this value as needed
    transform: 'translateY(40px)', // Move dropdown down by 10px
    minWidth: 240
  },
}));


const CustomPopper2 = (props) => (
  <Popper
    {...props}
    placement="bottom-end" // Position the dropdown relative to the bottom
    modifiers={[
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [drawerWidth, -40], // Adjust if needed
        },
      },
    ]}
  />
);

  // Custom debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);



  // Fetch accounts using Tanstack Query
  const {
      status: accountSearchsDataStatus,
        data: accounts = [],
        error: accountSearchError,
        isFetching: isGettingAccountData
  } = useFetchAccountSearch({debouncedInputValue});

  // Handle input change
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const history = useHistory ()
 // Handle option selection
  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption) {
      history.replace(`/accounts/${selectedOption.Id}`);
      // Clear input
      setInputValue('');
      // Update recent accounts
      setRecentAccounts((prev) => {
        const updated = [selectedOption, ...prev.filter((acc) => acc.Id !== selectedOption.Id)];
        return updated.slice(0, 5); // Keep only last 5
      });
    }
  };

  // Combine recent accounts and fetched accounts, placing recent accounts at the top
  const options = useMemo(() => {
    const uniqueOptions = new Map();
    recentAccounts.forEach((acc) => uniqueOptions.set(acc.Id, acc));
    accounts.forEach((acc) => {
      if (!uniqueOptions.has(acc.Id)) {
        uniqueOptions.set(acc.Id, acc);
      }
    });
    return Array.from(uniqueOptions.values());
  }, [accounts, recentAccounts]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.Name}
      options={options}
      freeSolo={true}
      size={"small"}
      loading={isGettingAccountData}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      PopperComponent={CustomPopper2} // Use the custom Popper here

      renderInput={(params) => (
        <Box padding={"8px"}>
          <TextField
          {...params}
          label="Search..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            // sx: {borderRadius: 0, border: "none"},
            endAdornment: (
              <>
                {isGettingAccountData ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
          </Box>
      )}
    />
  );
};

export default AccountSearch;
